<template>
  <div>
    <div class="mb-1">
      <vue-google-autocomplete
        id="map"
        ref="googleAddress"
        classname="form-control"
        placeholder="Ingresa una dirección"
        :country="['mx']"
        @placechanged="getAddressData"
      />
    </div>

    <GmapMap
      @click="setMarker($event)"
      :options="{
        streetViewControl: false,
        fullscreenControl: true,
        restriction: {
          latLngBounds: {
            north: 32.718,
            south: 14.532,
            west: -118.503,
            east: -86.589,
          },
          strictBounds: false,
        },
      }"
      :center="center"
      :zoom="localZoom"
      :style="`width: ${width}; height: ${height}`"
      map-type-id="roadmap"
    >
      <GmapMarker :position="marker" :clickable="true" />
    </GmapMap>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete"
import { getGoogleMapsAPI } from "gmap-vue"

import messagesMixin from "../mixins/messagesMixin"

export default {
  components: {
    VueGoogleAutocomplete,
  },
  mixins: [messagesMixin],
  props: {
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "500px",
    },
    zoom: {
      type: Number,
      default: 4,
    },
    latitude: {
      type: Number,
      default: 19.4326077,
    },
    longitude: {
      type: Number,
      default: -99.133208,
    },
  },
  watch: {
    latitude(val) {
      this.center = this.getBoundedLatLng(val, this.longitude)
      this.marker = this.center
    },
    longitude(val) {
      this.center = this.getBoundedLatLng(this.latitude, val)
      this.marker = this.center
    },
  },

  data() {
    return {
      center: { lat: Number(this.latitude), lng: Number(this.longitude) },
      localZoom: this.zoom,
      marker: {},
      address: {},
    }
  },

  created() {
    if (this.latitude !== 19.4326077 || this.longitude !== -99.133208) {
      this.marker = {
        lat: Number(this.latitude),
        lng: Number(this.longitude),
      }
      this.center = this.marker
    }
  },
  methods: {
    getBoundedLatLng(lat, lng) {
      const bounds = {
        north: 32.718,
        south: 14.532,
        west: -118.503,
        east: -86.589,
      }

      return {
        lat: Math.max(bounds.south, Math.min(bounds.north, lat)),
        lng: Math.max(bounds.west, Math.min(bounds.east, lng)),
      }
    },
    reverseGeocode(lat, lng) {
      const mapsapi = new getGoogleMapsAPI()

      mapsapi.maps.Geocoder.prototype.geocode(
        { location: { lat, lng } },
        (results, status) => {
          if (status === "OK" && results[0]) {
            this.$refs.googleAddress.$el.value = results[0].formatted_address
            this.setAddress(results[0])
          } else {
            this.toastError({
              title: "Introduzca la dirección manualmente",
              text: "No se encontró una dirección válida",
            })
          }
        }
      )
    },

    setMarker(event) {
      if (!event.latLng) return

      const { lat, lng } = this.getBoundedLatLng(
        Number(event.latLng.lat()),
        Number(event.latLng.lng())
      )

      this.marker = { lat, lng }
      this.center = { lat, lng }
      this.localZoom = 10
      this.$nextTick(() => {
        this.$forceUpdate()
      })

      this.reverseGeocode(lat, lng)
    },
    getAddressData(address) {
      this.address.state = address.administrative_area_level_1
      this.address.country = address.country
      this.address.city = address.locality
      this.address.street = address.route
      this.address.ext_number = address.street_number
      this.address.postal_code = address.postal_code
      this.address.lat = address.latitude
      this.address.lng = address.longitude
      this.address.suburb = address.sublocality_level_1
      this.address.formatted_address = [
        this.address.street,
        this.address.ext_number,
        this.address.city,
        this.address.state,
        this.address.country,
      ]
        .filter(Boolean)
        .join(", ")

      this.center = {
        lat: address.latitude,
        lng: address.longitude,
      }
      this.marker = {
        lat: address.latitude,
        lng: address.longitude,
      }
      this.localZoom = 10

      this.$emit("getAddress", this.address)
    },

    setAddress(place) {
      place.address_components.forEach((x) => {
        if (x.types.includes("administrative_area_level_1")) {
          this.address.state = x.long_name
        }
        if (x.types.includes("country")) {
          this.address.country = x.long_name
        }
        if (x.types.includes("city") || x.types.includes("locality")) {
          this.address.city = x.long_name
        }
        if (x.types.includes("street") || x.types.includes("route")) {
          this.address.street = x.long_name
        }
        if (
          x.types.includes("ext_number") ||
          x.types.includes("street_number")
        ) {
          this.address.ext_number = x.long_name
        }
        if (x.types.includes("postal_code")) {
          this.address.postal_code = x.long_name
        }
        if (
          x.types.includes("suburb") ||
          x.types.includes("sublocality_level_1")
        ) {
          this.address.suburb = x.long_name
        }
      })
      this.address.formatted_address = place.formatted_address
      this.address.lat = place.geometry.location.lat()
      this.address.lng = place.geometry.location.lng()
      this.address.url = place.url

      this.$emit("getAddress", this.address)
    },
  },
}
</script>

<style lang="scss" scoped></style>
