var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-1"},[_c('vue-google-autocomplete',{ref:"googleAddress",attrs:{"id":"map","classname":"form-control","placeholder":"Ingresa una dirección","country":['mx']},on:{"placechanged":_vm.getAddressData}})],1),_c('GmapMap',{style:(("width: " + _vm.width + "; height: " + _vm.height)),attrs:{"options":{
      streetViewControl: false,
      fullscreenControl: true,
      restriction: {
        latLngBounds: {
          north: 32.718,
          south: 14.532,
          west: -118.503,
          east: -86.589,
        },
        strictBounds: false,
      },
    },"center":_vm.center,"zoom":_vm.localZoom,"map-type-id":"roadmap"},on:{"click":function($event){return _vm.setMarker($event)}}},[_c('GmapMarker',{attrs:{"position":_vm.marker,"clickable":true}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }